import { cn } from '@lemonenergy/lemonpie-components'
import { Back, Barcode, ChevronRight, Help } from '@lemonenergy/lemonpie-icons'

type AppIconProps = {
  withSpacing?: boolean
}

export const AppChevronRight = () => (
  <ChevronRight className="fg-softer shrink-0" size="small" />
)

export const AppHelp = () => <Help size="large" />

export const AppBarcode = ({ withSpacing }: AppIconProps) => (
  <Barcode
    size="large"
    className={cn('shrink-0', withSpacing && 'mr-smallest')}
  />
)

export const AppBackButton = () => <Back /> // Implement styles
